import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import InputBase from '@mui/material/InputBase';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';

import { colors } from '../lib/styles';
import useUser from '../lib/useUser';
import Layout from '../components/layout';
import DownloadButton from '../components/download-csv';
import { MaskInput, Select } from '../lib/common';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'bsf_id',
    headerName: 'BSF',
    align: 'left',
    width: 100,
    renderCell: (params) => {
      return <Link style={{ color: colors.darkAccent }} to={`/bsfs/${params.row.bsf_id}`}>Detail</Link>;
    },
  },
  {
    field: 'carrier_name',
    headerName: 'Carrier',
    align: 'left',
    width: 150,
  },
  {
    field: 'client_firstname',
    headerName: 'Client First',
    align: 'left',
    width: 150,
    renderCell: (params) => {
      const { row } = params;
      const url = `/household/${row.household_id}/client/${row.client_id}`;
      return <Link style={{ color: colors.darkAccent }} to={url}>{row.client_firstname}</Link>;
    },
  },
  {
    field: 'client_lastname',
    headerName: 'Client Last',
    align: 'left',
    width: 150,
    renderCell: (params) => {
      const { row } = params;
      const url = `/household/${row.household_id}/client/${row.client_id}`;
      return <Link style={{ color: colors.darkAccent }} to={url}>{row.client_lastname}</Link>;
    },
  },
  {
    field: 'policy_number',
    headerName: 'Policy#',
    align: 'left',
    width: 150,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      return (
        <MaskInput
          mask_type="currency"
          id="amount"
          name="amount"
          value={params.row.amount}
          fullWidth
          readOnly
          customInput={InputBase}
          style={{ textAlign: 'end', fontSize: 14 }}
        />
      );
    },
  },
  {
    field: 'fbs',
    headerName: 'SH Business',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      return (
        <MaskInput
          mask_type="currency"
          id="fbs"
          name="fbs"
          value={params.row.fbs}
          fullWidth
          readOnly
          customInput={InputBase}
          style={{ textAlign: 'end', fontSize: 14 }}
        />
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'right',
    width: 100,
  },
  {
    field: 'created_at',
    headerName: 'Created',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      return <span>{params.row.created_at ? moment(params.row.created_at).format('YYYY-MM-DD') : ''}</span>;
    },
  },
];

const Payments = () => {
  const classes = useStyles();
  const [err] = useState(null);
  const [status, setStatus] = useState('all');
  const [type, setType] = useState('all');
  const user = useUser();
  let path = `/users/${user.id}/payments?orderBy=created_at`;
  if (status !== 'all') {
    path += `&status=${status}`;
  }
  if (type !== 'all') {
    path += `&type=${type}`;
  }
  const { data: payments, loading: paymentsLoading } = useSWR(path);
  let cleanPayments = [];
  if (payments) {
    cleanPayments = payments.map((p) => {
      return { ...p, fbs: Number(p.full_business_split), amount: Number(p.amount) };
    });
  }

  return (
    <Layout>
      <div>{err?.toString()}</div>
      <Paper>
        {!paymentsLoading ? (
          <div>
            <div
              style={{
                display: 'flex',
                // justifyContent: 'space-between',
                margin: 10,
              }}
            >
              <Select
                label="Status"
                value={status}
                name="status"
                onChange={(evt) => {
                  setStatus(evt.target.value);
                }}
                style={{ marginRight: 10, width: 150 }}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="OPEN">Open</MenuItem>
                <MenuItem value="PAID">Paid</MenuItem>
              </Select>
              <Select
                label="Type"
                value={type}
                name="type"
                onChange={(evt) => {
                  setType(evt.target.value);
                }}
                style={{ marginRight: 10, width: 150 }}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="LIFE">Life</MenuItem>
                <MenuItem value="ANNUITY">Annuity</MenuItem>
                <MenuItem value="HEDGEHOG">HedgeHog</MenuItem>
                <MenuItem value="LANDING_ROCK">Landing Rock</MenuItem>
              </Select>
            </div>
            <div>
              <DownloadButton rows={cleanPayments} name="payments"/>
            </div>
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={cleanPayments} />
            </div>
          </div>
        ) : <CircularProgress/>}
      </Paper>
    </Layout>
  );
};

export default Payments;
