/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
// Configure Material-UI
import { ThemeProvider } from '@mui/material/styles';
import '@fontsource/libre-baskerville';
import '@fontsource/maven-pro';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { promiseMiddleware } from 'cooldux';
import logger from 'redux-logger';
import { SWRConfig } from 'swr';

import { apiFetch } from './lib/fetch';
import theme from './theme';
import reducers from './state';

import ProtectedRoute from './components/protected-route';

import Home from './pages/home';

import AgentCity from './pages/agents-city';
import AgentDashboard from './pages/agent-dashboard';
import AgentStates from './pages/agent-states';
import BSFs from './pages/bsfs';
import BusinessResources from './pages/business-resources';
import Clients from './pages/clients';
import Chargebacks from './pages/chargebacks';
import ClientManagement from './pages/client-management';
import Contacts from './pages/contacts';
import Forgot from './pages/forgot';
import Login from './pages/login';
import MyNetwork from './pages/my_network';
import NewBSF from './pages/new-bsf';
import NewCSF from './pages/new-csf';
import NewHousehold from './pages/household-new';
import EditHousehold from './pages/household-edit';
import HouseholdNotesArchive from './pages/household-notes-archive';
import OverrideDetails from './pages/override_details';
import OverridePayments from './pages/overrides';
import Payments from './pages/payments';
import PubValidate from './pages/pub_validate';
import QualByMonth from './pages/qualified-by-month';
import Register from './pages/register-agent';
import RiskProfile from './pages/risk_profile_questionaire';
import RiskProfileAnswers from './pages/risk-profiles';
import RiskProfileAnswer from './pages/risk-profile-details';
import Reports from './pages/reports';
import ReviewBSF from './pages/review-bsf';
import Training from './pages/training';
import UpdatePassword from './pages/updatepassword';
import Validate from './pages/validate';
import Settings from './pages/settings';
import RegStep2 from './pages/reg-step2';
import EPSuccess from './pages/ep-success';
import PCSuccess from './pages/pc-success';
import HouseholdInvites from './pages/household_invites';
import StrongholdFiles from './pages/stronghold-files';
import ImageViewer from './pages/image-viewer';

import './index.css';

const middlewares = [thunkMiddleware, promiseMiddleware];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const store = createStore(reducers, applyMiddleware(...middlewares));

render(
  <Provider store={store}>
    <SWRConfig
      value={{
        refreshInterval: 0,
        fetcher: apiFetch,
      }}
    >
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <ProtectedRoute path="/agent-dashboard" component={AgentDashboard} />
            <ProtectedRoute path="/business-resources" component={BusinessResources} />
            <ProtectedRoute path="/training" component={Training} />
            <ProtectedRoute path="/contacts" component={Contacts} />
            <ProtectedRoute path="/client-management" component={ClientManagement} />
            <ProtectedRoute path="/reports" component={Reports} />
            <ProtectedRoute exact path="/bsfs/:id" component={ReviewBSF} />
            <ProtectedRoute exact path="/split-approval/:id" component={ReviewBSF} />
            <ProtectedRoute path="/bsfs" component={BSFs} />
            <ProtectedRoute path="/clients" component={Clients} />
            <ProtectedRoute path="/chargebacks" component={Chargebacks} />
            <ProtectedRoute exact path="/household/new" component={NewHousehold} />
            <ProtectedRoute exact path="/household/:householdId/edit" component={EditHousehold} />
            <ProtectedRoute exact path="/household/:householdId/client/:clientId" component={EditHousehold} />
            <ProtectedRoute exact path="/household/:householdId/client/:clientId/nav/:nav" component={EditHousehold} />
            <ProtectedRoute exact path="/household/:householdId" component={EditHousehold} />
            <ProtectedRoute exact path="/household/:householdId/nav/:nav" component={EditHousehold} />
            <ProtectedRoute path="/payments" component={Payments} />
            <ProtectedRoute path="/network" component={MyNetwork} />
            <ProtectedRoute path="/overrides" component={OverridePayments} />
            <ProtectedRoute path="/override_details/:overrideId" component={OverrideDetails} />
            <ProtectedRoute path="/household_invites" component={HouseholdInvites} />
            <ProtectedRoute exact path="/new-bsf" component={NewBSF} />
            <ProtectedRoute exact path="/new-bsf/:householdId/:clientId" component={NewBSF} />
            <ProtectedRoute path="/new-csf" component={NewCSF} />
            <ProtectedRoute path="/settings" component={Settings} />
            <ProtectedRoute path="/business_resources" component={StrongholdFiles} />
            <ProtectedRoute path="/reg/step2" component={RegStep2} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/login/:loc" component={Login} />
            <Route path="/validate/:token" component={Validate} />
            <Route path="/valid/:token" component={PubValidate} />
            <Route path="/reset/:token" component={UpdatePassword} />
            <Route path="/forgot" component={Forgot} />
            <Route exact path="/search" component={AgentStates} />
            <Route exact path="/search/:state" component={AgentCity} />
            <Route exact path="/register/:num" component={Register} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/risk_profile/:num" component={RiskProfile} />
            <ProtectedRoute exact path="/risk_profile_answers" component={RiskProfileAnswers} />
            <ProtectedRoute exact path="/risk_profile_answers/:id" component={RiskProfileAnswer} />
            {/* delete this next route (/risk_profiles/:id) after march 1 2025 */}
            <ProtectedRoute exact path="/risk_profiles/:id" component={RiskProfileAnswer} />
            <ProtectedRoute exact path="/household/:householdId/notes-archive" component={HouseholdNotesArchive} />
            <ProtectedRoute exact path="/qualified/:month" component={QualByMonth} />
            <ProtectedRoute exact path="/cc/ep_success/:evtId" component={EPSuccess} />
            <ProtectedRoute exact path="/cc/pc_success/:evtId" component={PCSuccess} />
            <ProtectedRoute exact path="/image-viewer/:type/:id" component={ImageViewer} />
            <ProtectedRoute exact path="/image-viewer/:type/:id/:clientId" component={ImageViewer} />
          </Switch>
        </Router>
      </ThemeProvider>
    </SWRConfig>
  </Provider>, document.getElementById('root'),
);

if (String(document.location.href).includes('strongholdpartners.com')) {
  document.location.href = 'https://agent.strongholdweatlhpartners.com';
}
